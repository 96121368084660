exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ai-js": () => import("./../../../src/pages/AI.js" /* webpackChunkName: "component---src-pages-ai-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kodawari-js": () => import("./../../../src/pages/kodawari.js" /* webpackChunkName: "component---src-pages-kodawari-js" */),
  "component---src-pages-legalnotes-js": () => import("./../../../src/pages/legalnotes.js" /* webpackChunkName: "component---src-pages-legalnotes-js" */),
  "component---src-pages-recruit-js": () => import("./../../../src/pages/recruit.js" /* webpackChunkName: "component---src-pages-recruit-js" */)
}

